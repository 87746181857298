/**
 * Element classes
 */
div.actions {
	text-align: right;

	button {
		margin-left: 10px;
	}
}

div.bigIcon {
	font-size: 3rem;
}

@media (max-width: 600px) {
	div.bigIcon {
		font-size: 2.5rem;
	}
}

div.errors {
	background-color: #ff0000;
	border: 2px solid #8e1717;
	border-radius: 10px;
	color: $cWhite;
	margin-bottom: 20px;
	max-width: 100%;
	padding: 10px;
	width: 600px;

	p {
		font-weight: bold;
	}
}

div.existingPhoto {
	background-attachment: scroll;
	background-color: $cBlack;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 800px;
	max-width: 400px;
	position: relative;

	i {
		background: $cWhite;
		color: $cError;
		font-size: 2rem;
		padding-left: 1px;
		position: absolute;
		border-radius: 15px;
		right: 10px;
		top: 10px;
	}
}

div.uploadText {
	border: 1px solid $cWhite;
	height: 400px;
	line-height: 360px;
	max-width: 400px;
	padding: 20px;
	text-align: center;

	p {
		display: inline-block;
		font-size: 1.5rem;
		line-height: 1.5;
		vertical-align: middle;
	}
}

div.legal {
	.MuiTypography-body1 {
		font-size: 0.75rem;
	}

	li {
		font-size: 0.75rem;
	}
}

div.singlePage {
	height: 100%;
	//padding: 10px;
}

h1 {
	font-family: 'Roboto', sans-serif;
	font-size: 2rem;
	font-weight: 900;
	line-height: 2.25rem;
}

h2 {
	font-family: 'Roboto', sans-serif;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 2rem;
}
h3 {}
h4 {}
h5 {}
h6 {}

hr.blue {
	border-color: $cBlue;
	margin: 0 25%;
}

hr.blue.small {
	margin: 0 33%;
}

hr.padded {
	margin-top: 5px;
	margin-bottom: 5px;
}

i.fas {
	cursor: pointer;
}

i.header {
	font-size: 2.0rem;
	margin-top: -20px;
}

i.open {
	color: $cGreen;
}

p {
	font-size: 1rem;
	line-height: 1.25rem;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
}

p.error {
	color: #ff0000;
	font-weight: bold;
}

ul.regular {
	list-style: initial;
	margin: initial;
	padding: 0 0 0 40px;

	li {
		display: list-item;
		margin: 5px 0;
	}
}

/**
 * No Element Classes
 */
.black {
	color: $cBlack;
}

.blue {
	color: $cBlue;
}

.blue_oval {
	border-radius: 15px;
	background: $cBlue;
	color: white;
	display: inline-block;
	font-size: .8rem;
	font-weight: bold;
	padding: 5px 10px;
	vertical-align: middle;
}

.bold {
	font-weight: bold;
}

.center {
	text-align: center;
	text-align: -webkit-center;
}

.container {
	width: 100%;
	display: block;
	box-sizing: border-box;
	margin-left: auto;
	margin-right: auto;
}

@media (min-width: 600px) {
	.container.sm {
		max-width: 600px;
	}
}

@media (min-width: 960px) {
	.container.md {
		max-width: 960px;
	}
}

.field {
	p {
		font-size: .8rem;
		margin-left: 5px;
		white-space: nowrap;
	}

	.MuiInputBase-root, .MuiFormControl-root {
		width: 100%;
	}
}

.justify {
	text-align: justify;
}

.link {
	cursor: pointer;
}

.options_button {
	background: rgba($cDarkerGrey, .85);
	border-radius: 0px 0px 10px 10px;
	color: $cWhite;
	cursor: pointer;
	font-weight: bold;
	left: 50%;
	padding: 10px 30px;
	position: absolute;
	top: 0;
	transform: translate(-50%, 0%);
	z-index: $z-dropdown;
}

.options_content {
	background: black;
	border-bottom: 1px solid black;
	color: $cWhite;
	padding: 10px;

	.MuiSelect-outlined, .MuiSelect-select:not([multiple]) {
		padding: 5px 10px;
	}

	.options_content_slider, .options_slider_type {
		padding: 0 10px;
	}
}

.padding {
	padding: 10px;
}

.red {
	color: $cRed;
}

.relative {
	position: relative;
}

.right {
	text-align: right;
}

.small-script {
	font-size: .75rem;
	line-height: 1rem;
}

@media only screen and (max-width: 360px) {

	h1 {
		font-size: 1.75rem;
		line-height: 2rem;
	}

	h2 {
		font-size: 1.25rem;
		line-height: 1.75rem;
	}
}