h1.MuiTypography-h1 {
	font-size: 2.5rem;
	font-weight: bold;
	margin: 10px 0;
	text-align: left;
}

h2.MuiTypography-h2 {
	font-size: 1.75rem;
	font-weight: bold;
	margin: 10px 0;
	text-align: left;
}

h3.MuiTypography-h3 {
	font-size: 1.25rem;
	font-weight: bold;
	margin: 10px 0;
	text-align: left;
}

div.MuiAccordionDetails-root {
	display: block;
}

div.MuiSnackbarContent-root {
	background-color: $cWhite;
	color: $cBlack;
	font-size: 1.25rem;
	font-weight: bold;
}

.MuiAppBar-colorDefault {
	color: $cWhite;
	background-color: $cBlack;
}

.MuiButton-contained, .MuiButton-containedPrimary {
	background-color: $cBlue;
	box-shadow: 0px 3px 1px -2px rgba($cBlue,0.2),0px 2px 2px 0px rgba($cBlue,0.14),0px 1px 5px 0px rgba($cBlue,0.12);
	color: $cWhite;
	font-weight: bold;
}

.MuiButton-contained.Mui-disabled, .MuiButton-contained:hover.Mui-disabled,
.MuiButton-containedPrimary.Mui-disabled, .MuiButton-containedPrimary:hover.Mui-disabled {
	background-color: $cGrey;
}

.MuiButton-contained.red, .MuiButton-containedSecondary {
	background-color: $cRed;
	box-shadow: 0px 3px 1px -2px rgba($cRed,0.2),0px 2px 2px 0px rgba($cRed,0.14),0px 1px 5px 0px rgba($cRed,0.12);
	color: $cWhite;
}

.MuiButton-contained.red.Mui-disabled, .MuiButton-contained:hover.red.Mui-disabled,
.MuiButton-containedSecondary.Mui-disabled, .MuiButton-containedSecondary:hover.Mui-disabled {
	background-color: rgba($cRed, 0.8);
}

.MuiButton-contained.white {
	background-color: $cWhite;
	box-shadow: 0px 3px 1px -2px rgba($cWhite,0.2),0px 2px 2px 0px rgba($cWhite,0.14),0px 1px 5px 0px rgba($cWhite,0.12);
	color: $cBlack;
}

.MuiButton-contained.white.Mui-disabled {
	background-color: rgba($cWhite, 0.8);
}

.MuiCheckbox-colorPrimary {
	.MuiIconButton-label {
		.MuiSvgIcon-root {
			fill: $cWhite;
		}
	}
}

.MuiChip-root {
	background: $cBlue;
	color: $cWhite;
	margin: 0 5px 5px 0;
}

.MuiChip-root:last-child {
	margin-right: 0;
}

.MuiIconButton-root {
	color: $cWhite;
}

td.actions {
	.MuiIconButton-root {
		color: $cGrey;
	}
}

.MuiInputAdornment-root {
	.MuiTypography-colorTextSecondary {
		color: $cWhite;
		font-size: 1rem;
		margin: 0;
		padding: 0;
	}
}

.MuiInputAdornment-root + input {
	padding-left: 0;
}

.MuiInputBase-root, .MuiFormControl-root {
	border: 1px solid $cLightGrey;
	border-radius: 5px;
}

.MuiInputBase-multiline {
	padding: 0px;
}

.MuiInputBase-root:hover, .MuiFormControl-root:hover {
	border-color: $cBlue;
}

.MuiInputLabel-outlined {
	color: $cLightGrey;
}

.MuiList-root {
	background-color: $cDarkestGrey;
	color: $cWhite;
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
	background-color: $cBlue;
	color: $cWhite;
}

.MuiOutlinedInput-input, .MuiSelect-outlined, .MuiSelect-select:not([multiple]) {
	background-color: $cDarkestGrey;
	color: $cLighterGrey;
	padding: 10px 15px;
}

option, optgroup {
	background: $cDarkestGrey;
	color: $cLightGrey;
}

.MuiOutlinedInput-adornedStart {
	background-color: $cDarkestGrey;
}

.MuiPopover-paper {
	max-height: 70vh;
}

.MuiPaper-root {
	margin-bottom: 10px;
}

.MuiSelect-icon, .MuiSelect-iconOutlined {
	color: $cLightGrey;
}

.MuiSelect-selectMenu {
	white-space: inherit;
}

.MuiSwitch-track {
	background-color: #b5b5b5;
}

.MuiSwitch-colorPrimary.Mui-checked {
	color: $cBlue;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
	background-color: $cDarkBlue;
}

.MuiSlider-mark, .MuiSlider-markActive {
	background-color: $cBlue;
	border-radius: 5px;
	color: $cBlue;
	height: 5px;
	margin-top: -2px;
	width: 5px;
}

.MuiSlider-markLabel {
	color: $cWhite;
	top: 26px !important;
}

.MuiSlider-root {
	color: $cWhite;
	padding: 13px 0 !important;
}

.MuiTableCell-body, .MuiTableCell-footer {
	background-color: $cWhite;
	color: $cBlack;
}

.MuiTypography-root.p {
	margin-bottom: 10px;
}

@media (max-width: 600px) {

	h1.MuiTypography-h1 {
		font-size: 2.5rem;
	}

	h2.MuiTypography-h2 {
		font-size: 2rem;
	}

	h3.MuiTypography-h3 {
		font-size: 1.5rem;
	}
}
