#view {
	background-color: $cBlack;
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	text-align: left;
	top: 0;
	$z-index: $z-view;

	div.clickLeft {
		color: $cBlue;
		font-size: 70px;
		left: 10px;
		position: absolute;
		text-shadow: -2px 0 $cWhite, 0 2px $cWhite, 2px 0 $cWhite, 0 -2px $cWhite;
		top: calc(50% - 35px);
	}

	div.clickRight {
		color: $cBlue;
		font-size: 70px;
		position: absolute;
		right: 10px;
		text-shadow: -2px 0 $cWhite, 0 2px $cWhite, 2px 0 $cWhite, 0 -2px $cWhite;
		top: calc(50% - 35px);
	}

	div.close {
		position: fixed;
		right: 5px;
		top: 50px;
		text-align: right;
		text-align: -webkit-right;
		width: 100%;
		z-index: $z-header;
	}

	div.details {
		padding: 10px;

		p {
			color: $cWhite;
		}

		p.name {
			font-size: 1.8rem;
			font-weight: bold;
		}

		p.posting_name {
			font-size: 1.4rem;
			font-weight: bold;
		}

		i.name_icon {
			font-size: 2.2rem;
		}

		p.type {
			display:table;
			font-size: 1.6rem;

			.salary {
				margin: -10px 0 0 0;
			}
		}

		p.posting_type {
			font-weight: bold;
		}

		p.skills {
			margin-bottom: 10px;

			.skill:not(:last-child):after {
				color: $cBlue;
				content: '\0020\00B7\0020';
				font-size: 1.2rem;
			}
		}

		p.starts {
			margin-bottom: 5px;
		}

		p.description {
			font-style: italic;
		}

		div.experience {
			margin: 10px 0;

			div.job {
				margin: 10px 0;

				p.establishment {
					font-weight: bold;
				}

				p.address {
					font-style: italic;
					text-align: right;
				}

				p.from_till {
					color: $cBlue;
					font-size: 0.9rem;
					font-style: italic;
				}
			}
		}

		div.languages {

			.blue_oval {
				margin-right: 5px;
			}
		}

		div.professions {
			color: $cWhite;
			font-weight: bold;
			margin-bottom: 10px;

			.profession {
				display: inline-block;
			}

			.profession:not(:last-child):after {
				color: $cBlue;
				content: '|';
				margin: 0 10px;
			}

			.profession:last-child:after {
				content: '';
			}
		}

		div.gig_dates {
			color: $cWhite;
			font-weight: bold;
			margin-bottom: 10px;
		}
	}

	div.gradient {
		background: linear-gradient(to bottom, transparent, 60%, black);
		height: 100%;
		position: relative;
	}

	div.image {
		background-attachment: scroll;
		background-color: $cBlack;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
	}

	div.public {
		background-color: transparent;
		display: table;
		height: 100%;
		text-align: center;
		width: 100%;
	}

	div.share {
		position: absolute;
		right: 10px;
		top: 10px;
		text-align: right;
		text-align: -webkit-right;
		z-index: $z-header;

		i {
			color: $cBlue;
			font-size: 2rem;
			text-shadow: -2px 0 $cWhite, 0 2px $cWhite, 2px 0 $cWhite, 0 -2px $cWhite;
		}
	}
}