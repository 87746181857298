/* special */
::-webkit-calendar-picker-indicator {
	filter: invert(1);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	filter: invert(1);
}

/* site */
html, body, #root {
	height: 100%;
}

body {
	background-color: rgba($cDarkerGrey, 1);
	color: $cWhite;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	color: $cWhite;
	text-decoration: none;
}

a.blue {
	color: $cBlue;
	text-decoration: none;
}

#header {
	background: rgba($cDarkerGrey, .85);
	border-bottom: 1px solid $cDarkGrey;
	color: $cLighterGrey;
	font-size: 1rem;
	font-weight: bold;
	padding: 5px 10px;
	z-index: $z-header;

	.title {
		font-size: inherit;
		font-weight: inherit;
		margin-bottom: 0 !important;

		.site-title {
			display: block;
			padding: .25rem;

			.titleIcon {
				width: 32px;
				height:auto;
				display: inline-block;
			}

			.titleLabel {
				display: inline-block;
				padding: .25rem 0 0 .25rem;
				font-size: .75rem;
			}
		}
	}

	.enterprise {
		padding: 0 10px;
		text-align: center;
		text-align: -webkit-center;

		.wrapper {
			max-width: 300px;
			margin: 0 auto;
		}

		.MuiInputBase-root {
			width: 100%;

			select {
				padding: 7px 15px;
			}
		}

		i {
			margin-left: 10px;
		}
	}

	a {
		text-decoration: none;
	}

	button {
		color: $cWhite;
	}

	img {
		vertical-align: middle;
	}

	.icons {
		line-height: 1.5;
		margin-top: 5px;

		i {
			margin-left: 15px;
		}
	}

	i.fas {
		position: relative;

		.count {
			background-color: $cBlue;
			border: 2px solid $cWhite;
			border-radius: 50%;
			color: $cWhite;
			font-size: 12px;
			height: 20px;
			line-height: 16px;
			position: absolute;
			right: -10px;
			text-align: center;
			top: -10px;
			width: 20px;
		}
	}
}

#content {
	position: relative;
}

#video {
	//margin-bottom: 5px;
	text-align: center;
}

#footer {
	p {
		font-size: 1rem;
		color: $cLightGrey;
		margin-bottom: 10px;
	}

	p.footer_icons {
		font-size: 2rem;
	}
}

#forgotPassword, #signin {
	background: $cBlack;
	border: 1px solid $cLightGrey;
	border-radius: 0 0 0 10px;
	color: $cWhite;
	max-width: 350px;
	padding: 10px;
	position: fixed;
	right: 0;
	top: 46px;
	width: 75%;
	z-index: $z-login;

	.error {
		color: #bb86fc;
		font-weight: bold;
		margin: 10px 0;
	}

	.field {
		margin-bottom: 10px;
	}

	.MuiInputBase-root, .MuiInputLabel-outlined {
		color: $cWhite;
	}

	.MuiOutlinedInput-notchedOutline {
		border-color: $cWhite;
	}
}

#menu {

	.menu_content {
		background-color: $cBlack;
		border-left: 1px solid $cWhite;
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 250px;

		.MuiListItemIcon-root {
			min-width: 40px;

			i.fas {
				color: $cWhite;
			}
		}

		.top {
			flex-basis: 0;
			flex-grow: 1;
			flex-shrink: 1;
			overflow: auto;

			.submenu {
				padding-left: 30px;
			}
		}

		.bottom {
			flex-basis: auto;
			flex-grow: 0;
			flex-shrink: 0;

			text-align: center;

			p {
				font-size: 0.85rem;
			}

			hr {
				border-top-color: $cBlue;
				margin-block-start: 0.2em;
				margin-block-end: 0.2em;
				margin-inline-start: auto;
				margin-inline-end: auto;
				width: 33%;
			}

			i {
				font-size: 1.5rem;
			}
		}
	}

	box.enterprise, box.locale {
		color: $cWhite;
		display: block;
		font-size: 1rem;
		padding: 6px 8px 6px 0;
		text-transform: inherit;
	}
}

#not_verified {
	background-color: #ffff00;
	color: $cBlack;
	padding: 10px;

	a, .link {
		color: $cBlack;
		font-weight: bold;
	}

	i.fas {
		font-size: 1.5rem;
	}

	p {
		padding-right: 10px;
	}
}

#offline {
	background-color: $cRed;
	padding: 4px;

	p {
		color: $cWhite;
		font-weight: bold;
		text-align: center;
	}
}

#signup {
	padding: 10px;

	.actions {
		margin-top: 20px;
	}
}

#continue {
	padding: 10px;

	.which {
		border: 1px solid $cLightGrey;
		border-radius: 5px;
		margin: 0 5px;
		padding: 10px;
		text-align: center;
	}
}

#continue {
	.which {
		min-height: 300px;
	}
}

@media (max-width: 600px) {
	#account, #continue {
		.which {
			margin: 5px 0;
			min-height: 0;
		}
	}
}

#errors {
	bottom: 0;
	left: 0;
	padding: 5px;
	position: absolute;
	right: 0;

	.error {
		background-color: $cError;
		border-radius: 10px;
		color: $cWhite;
		margin: 5px;
		padding: 10px;
	}
}