#admin {
	background: $cBlack;
	color: $cWhite;
	height: 100%;

	legend {
		float: none;
	}

	.header {

		.MuiIconButton-root {
			color: $cWhite;
		}
	}

	.MuiCheckbox-colorPrimary {
		.MuiIconButton-label {
			.MuiSvgIcon-root {
				fill: inherit;
			}
		}
	}

	.header {

		.title {
			font-size: 2rem;
			font-weight: bold;
		}

		button {
			position: relative;
			top: -15px;

			i.fas {
				font-size: 2.5rem;
			}
		}
	}

	.admin_templates {
		height: 100%;

		.MuiListItem-root {
			cursor: pointer;
		}

		.MuiListItem-root.selected {
			background-color: #333333;
			color: $cWhite;
		}

		.MuiListItem-root:hover {
			background-color: $cBlack;
			color: $cWhite;

			.MuiTypography-root {
				font-weight: bold;
			}
		}

		.MuiTextField-root {
			width: 100%;
		}
	}

	div.create {
		margin-bottom: 10px;
		padding: 10px;

		p.title {
			font-size: 1.5rem;
			margin-bottom: 10px;
		}

		.MuiFormControl-root {
			width: 100%;
		}
	}

	div.result {
		margin-bottom: 10px;
		padding: 10px;

		i.fas {
			font-size: 1.5rem;
			margin-left: 10px;
		}
	}

	.admin_awaiting_approval, .admin_incomplete_signup, .admin_user_employees, .admin_user_employers {

		img {
			height: auto;
			width: 400px;
		}

		.action_row {

			td {
				padding: 0;
			}
		}
	}

	.faqs_record_entry {
		.flexStatic {
			width: 100px;
		}
	}
}
