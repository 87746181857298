/* adapted sticky footer */
html, body {
	height: 100%;
}

body, .beta {
	display: flex;
	flex-direction: column;
}
/*
.page-content {
	flex: 1 0 auto;
}

.page-footer {
	flex-shrink: 0;
}*/

/* Content of the Beta page */
.beta {
	background-image: url('/images/home_background.jpg');
	background-color: $cDarkGrey;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

//	color: $cLighterGrey;
//	margin: 10px;
//	min-height: 100vh;
	position: relative;

	.beta-logo {
		width: 225px;
		margin:3rem auto 0;
		display: block;
		height:auto;
	}

	h1 {
		//color: $cLighterGrey;
		padding: 1.5rem 2rem 1rem;
		margin-top: 0;
		margin-bottom: .5rem;
		text-align: center;
	}

	h2 {
		margin-bottom: 1rem;
		text-align: center;
	}

	iframe.main_video {
		//height: 630px;
		//width: 1120px;
		height: 526px;
		width: 936px;
	}

	ul {
		text-align: center;
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			text-align: center;
			color: $cLightGrey;
			margin-bottom: .5rem;
		}
	}

	p {
		//color: $cLightGrey;
		margin-bottom: 1rem;
		text-align: center;
		text-shadow: 0 0 3px rgba($cDarkerGrey, 1);
	}

	.page-content {
		background: rgb(18,18,18);
		background: linear-gradient(180deg, rgba(18,18,18,1) 0%, rgba(18,18,18,0) 100%);

		.container {
			padding-right: var(--bs-gutter-x,.75rem);
			padding-left: var(--bs-gutter-x,.75rem);

			.row {
				justify-content: center !important;
				--bs-gutter-x: 1.5rem;
				--bs-gutter-y: 0;
				display: -webkit-flex;
				display: flex;
				-webkit-flex-wrap: wrap;
				flex-wrap: wrap;
				//margin-top: calc(var(--bs-gutter-y)*-1);
				//margin-right: calc(var(--bs-gutter-x)/-2);
				//margin-left: calc(var(--bs-gutter-x)/-2);

				.col-10 {
					width: 83.33333%;

					.small-script {
						margin-top: 3rem;
						text-shadow: 0 0 3px rgba($cDarkerGrey, 1);
					}

					.form {
						text-align: center;
						margin-bottom: 4rem;
					}

					.form-control {
						margin: 0 0 1rem;
						display: block;
						width: 100%;
						padding: .375rem .75rem;
						font-size: 1rem;
						font-weight: 400;
						line-height: 1.5;
						color: #212529;
						background-color: $cWhite;
						background-clip: padding-box;
						border: 1px solid #ced4da;
						-webkit-appearance: none;
						appearance: none;
						border-radius: .25rem;
						transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
					}

					.btn {
						background: rgba($cNotification, .85);
						transition: background .5s;
						color: $cLighterGrey;

						display: inline-block;
						font-weight: 400;
						line-height: 1.5;
						text-align: center;
						text-decoration: none;
						vertical-align: middle;
						cursor: pointer;
						user-select: none;
						border: 1px solid transparent;
						padding: .375rem .75rem;
						font-size: 1rem;
						border-radius: .25rem;

						&:hover {
							background: rgba($cNotification, 1);
						}
					}
				}
			}

			.faq {
				background: rgba($cDarkerGrey, .85);
				margin-bottom: 5px;

				.faq_title {
					padding: 20px 10px;
					text-align: center;

					p {
						font-size: 1.35rem;
						font-weight: bold;
						margin: 0;
					}
				}

				.faq_content {

					.faq_question {
						background-color: $cGrey;
						margin-top: 1px;
						padding: 10px;
					}

					.faq_answer {
						background-color: #878787;
						padding: 10px;

						p {
							text-align: left;
						}
					}
				}
			}
		}
	}

	.page-footer {
		background: rgba($cDarkerGrey, 1);
		padding: 1.5rem 20px 0 20px;
		border-top: 1px solid $cDarkGrey;

		.copyrights {
			padding:1rem 0 0;

			p {
				font-size: .66rem;
				margin:0;
				padding: 1rem 0;
				line-height: 1;
			}
		}
	}
}

@media only screen and (max-width: 960px) {
	.beta {
		iframe.main_video {
			height: 315px;
			width: 560px;
		}
	}
}

@media only screen and (max-width: 600px) {
	.beta {
		iframe.main_video {
			height: 197px;
			width: 350px;
		}

		.page-content {
			.container {
				.faq {
					.faq_title {
						p {
							font-size: 1.15rem;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 360px) {
	.beta {
		iframe.main_video {
			height: 189px;
			width: 336px;
		}
	}
}