/* colors */
$cBlack: #000;
$cWhite: #f2f2f2;

$cGreen: #59f166;
$cRed: #d50000;

$cDarkGrey: #1e1e1e;
$cDarkerGrey: #121212;
$cDarkestGrey: #252525;

$cGrey: #545454;

$cLightGrey: #a7a7a7;
$cLighterGrey: #f2f2f2;

$cBlue: #2F8BB9;
$cDarkBlue: #2F8BB9;

$cError: #d93644;
$cAlert: #ff6f26;
$cWarning: #ffc926;
$cAgreed: #39a85e;
$cNotification: #36a3d9;

/* z-indexes */
$z-base: 10;
$z-dropdown: 20;
$z-view: 30;
$z-header: 40;
$z-login: 50;
