.employer_posting {
	margin-bottom: 10px;
	padding: 10px;

	.salary {
		font-size: .7rem;
	}

	i.fas {
		font-size: 2.5rem;
		margin-top: 10px;
		position: relative;

		.count {
			background-color: $cBlue;
			border: 2px solid $cWhite;
			border-radius: 50%;
			color: $cWhite;
			font-size: 16px;
			height: 25px;
			line-height: 21px;
			position: absolute;
			right: -10px;
			text-align: center;
			top: -10px;
			width: 25px;
		}
	}
}

@media (max-width: 600px) {
	.employer_posting {
		i.fas {
			font-size: 2.0rem;
		}
	}
}

.employer_posting_matches {
	padding: 10px;

	.match {
		background-color: $cGrey;
		color: $cWhite;
		cursor: pointer;
		padding: 5px 10px;
		margin-bottom: 5px;

		p {
			font-weight: bold;
		}
	}
}

.employer_posting_share {

	.MuiPaper-root {
		border: 1px solid $cGrey;
		border-radius: 10px;
	}

	.MuiDialogContent-root {
		background: $cBlack;
		color: $cBlue;
		padding: 15px;

		.social {
			margin-bottom: 10px;

			i {
				font-size: 2.25rem;
			}
		}

		.url {

			input {
				background: $cBlack;
				border-color: transparent;
				color: $cWhite;
				font-size: 1.25rem;
				width: 100%;
			}

			i {
				font-size: 1.25rem;
				padding: 3px 10px;
			}
		}
	}
}