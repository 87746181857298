.conversations {

	hr.MuiDivider-root {
		background-color: rgba($cBlack, 1);
	}

	p {
		color: $cLightGrey;
	}

	p.secondary {
		font-size: 0.8rem;
	}

	div.unread {

		p {
			color: $cWhite;
		}

		div.count {
			position: relative;

			p {
				background-color: $cBlue;
				border: 2px solid $cWhite;
				border-radius: 50%;
				color: $cWhite;
				font-size: 1rem;
				height: 25px;
				line-height: 21px;
				position: relative;
				text-align: center;
				top: -5px;
				width: 25px;
			}
		}
	}

	i.fas {
		margin-left: 5px;
	}
}

.conversations_menu_item {
	min-width: 220px;
}

.conversation {
	background-color: $cBlack;
	height: 100%;
	overflow: auto;

	.conversation_display {
		border-bottom: 1px solid $cGrey;
		border-top: 1px solid $cGrey;
		padding: 10px 0;

		p {
			font-size: 1.1rem;
			font-weight: bold;
			margin: 0 20px;
		}

		i.fa-save {
			font-size: 1.5rem;
			padding: 10px;
		}

		.flexStatic {

			i.menu {
				padding: 5px 20px 0;
			}
		}
	}

	.conversation_messages {
		padding: 5px;
	}

	.conversation_scroll {
		height: 0;
		margin: 0;
		padding: 0;
	}

	.conversation_send {
		flex-grow: 0;
		flex-shrink: 0;
		display: flex;
		flex-direction: row;
		width: 100%;

		.MuiTextField-root {
			flex-grow: 1;
		}

		button {
			flex-grow: 0;
			width: 100px;
		}
	}

	.conversation_system {
		color: $cWhite;
		text-align: center;
		p {
			font-size: 0.8rem;
			margin-bottom: 5px;
		}
	}

	.conversation_message:before {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
	}

	.conversation_message {
		border-radius: 10px;
		color: $cBlack;
		margin-bottom: 10px;
		padding: 10px;
		position: relative;
		width: 80%;
	}

	.conversation_message:after {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
	}

	.conversation_message.incoming:before {
		border-top: 17px solid #97c6e3;
		border-left: 16px solid transparent;
		border-right: 16px solid transparent;
		left: -17px;
		top: -1px;
	}

	.conversation_message.incoming {
		background-color: #a8ddfd;
		border: 1px solid #97c6e3;
		margin-left: 20px;

		a {
			color: #6392b0;
		}

		a:hover {
			color: #b08851;
		}
	}

	.conversation_message.incoming:after {
		border-top: 15px solid #a8ddfd;
		border-left: 15px solid transparent;
		border-right: 15px solid transparent;
		left: -15px;
		top: 0;
	}

	.conversation_message.outgoing:before {
		border-bottom: 17px solid #dfd087;
		border-left: 16px solid transparent;
		border-right: 16px solid transparent;
		bottom: -1px;
		right: -17px;
	}

	.conversation_message.outgoing {
		background-color: #f8e896;
		border: 1px solid #dfd087;
		margin-left: calc(20% - 17px);

		.conversation_footer {
			text-align: right;
		}
	}

	.conversation_message.outgoing:after {
		border-bottom: 15px solid #f8e896;
		border-left: 15px solid transparent;
		border-right: 15px solid transparent;
		bottom: 0;
		right: -15px;
	}

	.conversation_message_content {
		p {
			font-size: 1.25rem;
		}
	}

	.conversation_footer {
		font-size: 0.8rem;
	}
}
