.flexColumns {
	display: flex;
	flex-direction: row;
}

.flexRows {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.flexColumns, .flexRows {

	.flexGrow {
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 1;
	}

	.flexStatic {
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.flexRows > .flexGrow {
	height: 100%;
	overflow: auto;
}